

<template>

    <Plotly :data="data" :layout="layout" :display-mode-bar="false"></Plotly>

</template>
   
<script>
import { Plotly } from 'vue-plotly'

export default {
    name: 'PieChart',
    props: {
        pieData: {}
    },
    components: {
        Plotly
    },
    data() {

        let values = [];
        let labels = [];
        let ultimatecolor = [];

        this.pieData.forEach((item) => {
            values.push(item.price);
            labels.push(item.labels);
            ultimatecolor.push(item.color);
        });

        return {
            data: [{
                values: values,
                labels: labels,
                marker: {
                    colors: ultimatecolor
                },
                type: 'pie',
                outsidetextfont: { color: 'transparent' },

            }],
            layout: {
                height: 400,
                width: 400,
                plot_bgcolor: "rgba(0, 0, 0, 0)",
                paper_bgcolor: "rgba(0, 0, 0, 0)",
                showlegend: false,
                textposition: 'inside',
                textinfo: 'none',
                outsidetextfont: { color: 'transparent' },
                font: {
                    family: 'Montserrat',
                    size: 12,
                    weight: 800,
                    color: 'black'
                },



            }
        }
    }
}
</script>
   
<style>

</style>
  